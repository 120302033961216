import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Skeleton } from 'primereact/skeleton'

type Props = {
  showFolderName?: boolean
}

export default function SurveysTableSkeleton({ showFolderName }: Props) {
  return (
    <DataTable value={Array.from({ length: 10 })}>
      <Column
        body={() => <Skeleton height='0.75rem' width='16rem' />}
        className='w-auto'
        field='name'
        header='Project name'
        sortable
        sortableDisabled
      />

      {showFolderName && (
        <Column
          body={() => <Skeleton height='0.75rem' width='6rem' />}
          className='w-36'
          field='clientName'
          header='Client'
          sortable
          sortableDisabled
        />
      )}

      <Column
        body={() => <Skeleton height='0.75rem' width='5rem' />}
        className='w-28'
        field='surveyStatus'
        header='Status'
        sortable
        sortableDisabled
      />

      <Column
        body={() => <Skeleton height='0.75rem' width='5rem' />}
        className='w-5 text-center'
        field='assignee'
        header='Assignee'
        sortable
        sortableDisabled
      />

      <Column
        body={() => <Skeleton height='0.75rem' width='5rem' />}
        className='w-28'
        header='Target N'
        sortable
        sortableDisabled
      />

      <Column
        body={() => <Skeleton height='0.75rem' width='5rem' />}
        className='w-32 text-center'
        header='Deadline'
        headerClassName='flex justify-end pr-2'
        sortable
        sortableDisabled
      />
    </DataTable>
  )
}
