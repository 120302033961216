import EmptyState from '@components/EmptyState'
import HomePageContentLayout from '@views/surveys/HomePageContentLayout'
import { useFilteredSurveys } from '@views/surveys/hooks/use-filtered-surveys'
import { ProjectsView } from '@views/surveys/ProjectsPageContainer'
import SurveysActions from '@views/surveys/SurveysActions'
import SurveysTable from '@views/surveys/SurveysTable'

import type { Surveys, SurveysSearchParams } from '@typings/Survey'

type Props = {
  name: string
  view: ProjectsView
  searchParams?: SurveysSearchParams
}

export default function Surveys({ name, searchParams, view }: Props) {
  const { surveys, surveysError, surveysFilter } = useFilteredSurveys(searchParams, view)

  if (!surveys) {
    return null
  }

  return (
    <>
      {surveysError ? (
        <div className='text-red-600'>Error</div>
      ) : (
        <>
          <HomePageContentLayout
            actionElements={
              <SurveysActions hideActions={view === 'STARRED'} surveysFilter={surveysFilter} />
            }
            name={name}
          >
            <>
              {surveys?.length > 0 ? (
                <SurveysTable showFolderName surveys={surveys} />
              ) : (
                <EmptyState text={`You don’t have any ${view.toLowerCase()} projects.`} />
              )}
            </>
          </HomePageContentLayout>
        </>
      )}
    </>
  )
}
