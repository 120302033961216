import { cx } from '@helpers/cx'

type Props = {
  text: string
  description?: string
  children?: React.ReactNode
  className?: string
}

export default function EmptyState({ children, className, description, text }: Props) {
  const hasDescription = Boolean(description)

  return (
    <div
      className={cx(
        'flex w-full flex-col items-center justify-center space-y-4 rounded-md border-2 border-dashed border-gray-200 p-20 text-center',
        className,
      )}
    >
      <div className='space-y-1'>
        {hasDescription && <p className='h3'>{text}</p>}
        {hasDescription ? (
          <p className='text-sm text-gray-600'>{description}</p>
        ) : (
          <p className='text-sm text-gray-600'>{text}</p>
        )}
      </div>
      {children}
    </div>
  )
}
