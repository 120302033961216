import { usePermissions } from '@hooks/use-permissions'
import HomepageLayout from '@views/surveys/HomepageLayout'
import Surveys from '@views/surveys/Surveys'

import type { SurveysSearchParams } from '@typings/Survey'

export type ProjectsView = 'REGULAR' | 'ARCHIVED' | 'PERSONAL' | 'SEARCH_RESULTS' | 'STARRED'

export const PROJECTS_VIEW: Record<ProjectsView, ProjectsView> = {
  REGULAR: 'REGULAR',
  ARCHIVED: 'ARCHIVED',
  PERSONAL: 'PERSONAL',
  SEARCH_RESULTS: 'SEARCH_RESULTS',
  STARRED: 'STARRED',
}

type Props = {
  name: string
  searchParams?: SurveysSearchParams
  view: ProjectsView
}

export default function ProjectsPageContainer({ name, searchParams, view }: Props) {
  const { can } = usePermissions()

  return (
    <div>
      {can('read', 'Projects') && (
        <HomepageLayout type='projects'>
          <Surveys name={name} searchParams={searchParams} view={view} />
        </HomepageLayout>
      )}
    </div>
  )
}
