import { useEffect, useState } from 'react'

import { useRouter } from 'next/router'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'

import WorkflowStateProgress from '@components/WorkflowStateProgress'
import { WORKFLOW_STATE_TYPES } from '@constants'
import openLinkOnCmdClick from '@helpers/open-link-on-cmd-click'
import { usePermissions } from '@hooks/use-permissions'
import { useProjectsStats } from '@hooks/use-pµs-api'
import { getStarredProjectStorage } from '@views/surveys/helpers/handle-starred-project-storage'
import AssigneeColumn from '@views/surveys/SurveysTableComponents/AssigneeColumn'
import DeadlineColumn from '@views/surveys/SurveysTableComponents/DeadlineColumn'
import StarredColumn from '@views/surveys/SurveysTableComponents/StarredColumn'
import TargetQuantityColumn from '@views/surveys/SurveysTableComponents/TargetQuantityColumn'
import SurveysTableSkeleton from '@views/surveys/SurveysTableSkeleton'
import SurveyStatusBadge from '@views/surveys/SurveyStatusBadge'

import type { Surveys } from '@typings/Survey'

type Props = {
  showFolderName?: boolean
  surveys?: Surveys
}

export default function SurveysTable({ showFolderName, surveys }: Props) {
  const { can } = usePermissions()
  const router = useRouter()

  const { isLoadingProjectsStats, projectsStats } = useProjectsStats()
  const [starredProjects, setStarredProjects] = useState(getStarredProjectStorage)

  useEffect(() => {
    const handleStorageChange = () => setStarredProjects(getStarredProjectStorage())
    window.addEventListener('storage', handleStorageChange)

    return () => window.addEventListener('storage', handleStorageChange)
  }, [starredProjects])

  const canOpenProject = can('read', 'Project')
  const canViewAnalytics = can('read', 'Analytics')

  if (!surveys) {
    return <SurveysTableSkeleton showFolderName={showFolderName} />
  }

  return (
    <div>
      <DataTable
        className={canOpenProject ? 'cursor-pointer' : ''}
        rowHover={canOpenProject}
        sortField='surveyStatus'
        sortOrder={-1}
        value={surveys}
        onRowClick={(e) => {
          if (canOpenProject) {
            const { id: surveyId } = e.data
            openLinkOnCmdClick({ router, link: `/survey/${surveyId}`, event: e.originalEvent })
          }
        }}
      >
        <Column
          body={({ id: projectId }) => (
            <StarredColumn projectId={projectId} starredProjects={starredProjects} />
          )}
          className='w-5 text-center'
        />

        <Column
          body={({ name }) => <span className='font-semibold'>{name}</span>}
          className='w-auto'
          field='name'
          header='Project name'
          sortable
        />

        {showFolderName && (
          <Column className='w-36 whitespace-nowrap' field='clientName' header='Folder' sortable />
        )}

        <Column
          body={({ surveyStatus }) => <SurveyStatusBadge surveyStatus={surveyStatus} />}
          className='w-28'
          field='surveyStatus'
          header='Status'
          sortable
        />

        <Column
          body={({ assignee, id }) => <AssigneeColumn assigneeEmail={assignee} id={id} />}
          className='w-5 text-center'
          field='assignee'
          header='Assignee'
          sortable
        />

        {canViewAnalytics && (
          <Column
            body={({ id }) => (
              <TargetQuantityColumn
                isLoading={isLoadingProjectsStats}
                projectId={id}
                projectsStats={projectsStats}
              />
            )}
            className='w-28'
            header='Target N'
          />
        )}

        {canViewAnalytics && (
          <Column
            body={({ id }) => (
              <WorkflowStateProgress
                hiddenStates={[WORKFLOW_STATE_TYPES.NOT_ACCEPTED]}
                isLoading={isLoadingProjectsStats}
                itemId={id}
                projectStats={projectsStats?.[id]}
              />
            )}
            className='w-40 text-center'
            header='Collected responses'
          />
        )}

        <Column
          body={({ deadline, id }) => <DeadlineColumn deadline={deadline} id={id} />}
          className='w-32 text-center'
          field='deadline'
          header='Deadline'
          headerClassName='flex justify-end pr-2'
          sortable
        />
      </DataTable>
    </div>
  )
}
